import React from 'react';
import { Box } from '@mui/material';

import ProvidersList from './ProvidersList';
import GoogleProvider from './GoogleProvider';
import PhoneProvider from './PhoneProvider';

interface Props {
  className?: string;
}

interface State {
  currentView: 'default' | 'googleProvider' | 'phoneProvider';
}

type Action = {
  type: 'setCurrentView';
  payload: State['currentView'];
};

const initialState: State = {
  currentView: 'default',
};

function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'setCurrentView':
      return { ...state, currentView: action.payload };

    default:
      throw new Error('Unknown action');
  }
}

const Providers: React.FC<Props> = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const onProviderClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = e.currentTarget.dataset;

    switch (id) {
      case 'google': {
        dispatch({ type: 'setCurrentView', payload: 'googleProvider' });
        break;
      }

      case 'phone': {
        dispatch({ type: 'setCurrentView', payload: 'phoneProvider' });
        break;
      }

      default:
        break;
    }
  };

  const setDefaultCurrentView = () => {
    dispatch({ type: 'setCurrentView', payload: 'default' });
  };

  return (
    <Box>
      {['default', 'googleProvider'].includes(state.currentView) && <ProvidersList onProviderClick={onProviderClick} />}
      {state.currentView === 'googleProvider' && <GoogleProvider onError={setDefaultCurrentView} />}
      {state.currentView === 'phoneProvider' && <PhoneProvider onBackClick={setDefaultCurrentView} />}
    </Box>
  );
};

export default Providers;
