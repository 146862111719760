import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAppDispatch } from 'src/hooks/store';
import { confirmation } from 'src/store/ux';
import { ModalContext, ModalContextType, BlockingTexts } from './context';
import sx from './sx';

type Props = {
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
};

const Modal = (props: Props) => {
  const { children, open, onClose } = props;
  const [blockingTexts, setBlockingTexts] = React.useState<BlockingTexts>({
    message: 'Are you sure you want to close this popup?',
  });
  const isBlockedRef = React.useRef(false);
  const theme = useTheme();
  const style = sx(theme);
  const dispatch = useAppDispatch();

  const context = React.useMemo<ModalContextType>(
    () => ({
      setBlockingTexts,
      setIsBlocked(value: boolean) {
        isBlockedRef.current = value;
      },
      async close() {
        if (isBlockedRef.current) {
          dispatch(
            confirmation({
              title: blockingTexts.message || 'Close modal?',
              confirmBtn: blockingTexts.confirmLabel || 'Continue',
              cancelBtn: blockingTexts.cancelLabel || 'Cancel',
              callback: (result) => {
                if (result) {
                  onClose();
                }
              },
            }),
          );
        } else {
          onClose();
        }
      },
    }),
    [blockingTexts, onClose],
  );

  React.useEffect(() => {
    // document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  if (!open) {
    return null;
  }

  const onCloseClick = () => {
    context.close();
  };

  return (
    <ModalContext.Provider value={context}>
      <Box sx={style.container}>
        <Paper sx={style.modal}>
          <ButtonBase sx={style.closeBtn} onClick={onCloseClick}>
            <CloseIcon />
          </ButtonBase>
          <Box sx={style.modalContent}>{children}</Box>
        </Paper>
      </Box>
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalContextType };

export default Modal;
