import { sx } from 'src/utils';

export default sx((theme) => ({
  richField: {
    position: 'relative',
    width: 'calc(100% - 2px)',
    height: '310px',
    mb: '0',
    borderRadius: '10px',
    backgroundColor: 'trnsparent',

    padding: '12px 10px 10px',
    ...(theme?.palette.mode === 'dark'
      ? {
          outline: '1px solid rgba(255, 255, 255, 0.2)',

          '&:hover': {
            outline: `1px solid rgba(255, 255, 255, 1)`,
          },
          '&:focus-within': {
            outline: `2px solid ${theme?.palette.primary[300]}}`,
          },
          '&:focus-within > .label': {
            color: theme?.palette.primary[400],
          },
        }
      : {
          outline: '1px solid rgba(0, 0, 0, 0.2)',

          '&:hover': {
            outline: `1px solid rgba(0, 0, 0, 1)`,
          },
          '&:focus-within': {
            outline: `2px solid ${theme?.palette.primary[300]}}`,
          },
          '&:focus-within > .label': {
            color: theme?.palette.primary[400],
          },
        }),

    '& .MuiFormLabel-root': {
      color: 'rgba(255, 255, 255, 0.7)',
      backgroundColor: '#16232b',
      padding: '0 5px',
      transform: 'translate(11px, -10px) scale(0.77)',
    },

    // editor styles
    '& .editor': {
      backgroundColor: 'transparent',
      borderColor: '#4c565c',
      borderRadius: '10px',
      padding: '5px 0 10px',
    },
    '& button[title="Undo"], button[title="Redo"], .button': {
      background: theme?.palette.primary[100],
    },
    '.button[aria-checked="true"]': {
      background: theme?.palette.primary[300],
    },
    '& span': {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 'normal',
    },

    '& .rdw-editor-main': {
      height: '240px !important',
      paddingRight: '20px',
    },

    '& .DraftEditor-root': {
      // height: 'calc(100% - 15px) !important',
    },
  },
  errorMessage: {
    display: 'block',
  },
  label: {
    position: 'absolute',
    top: '-13px',
    left: '5px',
    color: 'rgba(255, 255, 255, 0.7)',
    backgroundColor: '#16232b',
    padding: '0 5px',
    transform: 'scale(0.77)',
  },
}));
