import React from 'react';
import { Box, TextField, IconButton, InputAdornment, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import sx from './sx';

interface Props {
  value?: string;
  onSearch: (v: string) => void;
}

const DEFAULT_VALUE = '';

const Search = (props: Props) => {
  const { onSearch } = props;
  const [value, setValue] = React.useState(props.value || DEFAULT_VALUE);
  const theme = useTheme();
  const style = sx(theme);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onSearch(e.target.value);
  };

  const onClearSearch = () => {
    setValue(DEFAULT_VALUE);
    onSearch(DEFAULT_VALUE);
  };

  React.useEffect(() => {
    if (props.value !== undefined) setValue(props.value);
  }, [props.value]);

  return (
    <Box>
      <Box sx={{ ...style.search }}>
        <TextField
          sx={{ m: '0 auto 5px', width: '500px', '& .MuiInputBase-root': { borderRadius: '30px' } }}
          placeholder="Search"
          value={value}
          variant="outlined"
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disabled={!value} color="primary" size="small" onClick={onClearSearch}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Search;
