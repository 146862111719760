import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

interface Props extends IconButtonProps {
  contentToCopy: string;
}

const CopyButton = (props: Props) => {
  const { contentToCopy, ...rest } = props;
  const [isCopied, setIsCopied] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState<any>();
  const theme = useTheme();

  const onCopyClick = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    await navigator.clipboard.writeText(contentToCopy);
    setIsCopied(true);

    const timeoutId = setTimeout(() => {
      setIsCopied(false);
    }, 3000);

    setTimeoutId(timeoutId);
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return (
    <IconButton
      {...rest}
      onClick={onCopyClick}
      sx={{
        pointerEvents: isCopied ? 'none' : 'visible',
        '& .MuiSvgIcon-root': { transform: 'scale(0.8)' },
        ...props.sx,
      }}
    >
      {isCopied ? (
        <CheckIcon fontSize={props.size} sx={{ color: theme.palette.success.light }} />
      ) : (
        <ContentCopyIcon fontSize={props.size} />
      )}
    </IconButton>
  );
};

export default CopyButton;
