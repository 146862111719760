import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { confirmation, ResolveConfirmation } from './actions/confirmation';

export interface IUX {
  confirmation: {
    title: string;
    confirmBtn?: string;
    cancelBtn?: string;
    callback: (result: boolean) => void;
  } | null;
  alertMessage: {
    severity: 'success' | 'error';
    message: string;
  } | null;
}

const initialState: IUX = {
  confirmation: null,
  alertMessage: null,
};

export const ux = createSlice({
  name: 'ux',
  initialState,
  reducers: {
    setAlertMessage: (state, action: PayloadAction<IUX['alertMessage']>) => {
      state.alertMessage = action.payload;
    },
    resolveConfirmation: (state, action: PayloadAction<ResolveConfirmation>) => {
      if (state.confirmation) {
        // state.confirmation.callback(action.payload.result);
        state.confirmation = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(confirmation.fulfilled, (state, action) => {
      state.confirmation = {
        title: action.payload.title,
        confirmBtn: action.payload.confirmBtn,
        cancelBtn: action.payload.cancelBtn,
        callback: action.payload.callback,
      };
    });
  },
});

export const { setAlertMessage, resolveConfirmation } = ux.actions;
export { confirmation };
export default ux.reducer;
