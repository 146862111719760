import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

import 'src/styles/index.scss';
import { Head, Modal, Snackbar } from 'src/components/common';
import Confirmation from 'src/components/common/Modal/Confirmation';
import { ThemeWrapper } from 'src/Theme';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { resolveConfirmation } from 'src/store/ux';
import { selectUX } from 'src/store/ux/selectors';

import SideBar from './SideBar';
import Content from './Content';

const PageLayout = () => {
  const dispatch = useAppDispatch();
  const ux = useAppSelector(selectUX);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const closeModal = React.useCallback(() => {
    ux.confirmation?.callback(false);
    dispatch(resolveConfirmation({ result: false }));
  }, [ux.confirmation?.callback]);

  const approveConfirmation = React.useCallback(() => {
    ux.confirmation?.callback(true);
    dispatch(resolveConfirmation({ result: true }));
  }, [ux.confirmation?.callback]);

  return (
    <ThemeWrapper>
      <Head />
      <Box display={isMobile ? 'block' : 'flex'} width="100%" height="100%">
        <SideBar />
        <Content />
      </Box>
      <Snackbar />
      <Modal open={!!ux.confirmation} onClose={closeModal}>
        <Confirmation
          confirmLabel={ux.confirmation?.confirmBtn}
          cancelLabel={ux.confirmation?.cancelBtn}
          onConfirm={approveConfirmation}
          onCancel={closeModal}
        >
          <Typography variant="h5">{ux.confirmation?.title}</Typography>
        </Confirmation>
      </Modal>
    </ThemeWrapper>
  );
};

export default PageLayout;
