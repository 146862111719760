import { createAsyncThunk } from '@reduxjs/toolkit';

interface Params {
  title: string;
  confirmBtn?: string;
  cancelBtn?: string;
  callback: (result: boolean) => void;
}

type Result = {
  title: string;
  confirmBtn?: string;
  cancelBtn?: string;
  callback: (result: boolean) => void;
};

export const confirmation = createAsyncThunk<Result, Params, { rejectValue: null }>(
  'ux/confirm',
  async (params, { rejectWithValue }) => {
    try {
      return {
        title: params.title,
        confirmBtn: params.confirmBtn,
        cancelBtn: params.cancelBtn,
        callback: params.callback,
      };
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export interface ResolveConfirmation {
  result: boolean;
}
