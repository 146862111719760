import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import NoSsr from '@mui/material/NoSsr';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CircularProgress from '@mui/material/CircularProgress';
import cn from 'classnames';

import { Row, SortableRow } from './Row';
import css from './Table.module.scss';

export type TableColumns<D = Record<string, unknown>> = {
  [key: string]: {
    header: React.ReactNode | (() => React.ReactNode);
    className?: string;
    currentSort?: 'asc' | 'desc';
    render: (data: D) => React.ReactNode;
    onSort?: (sort?: 'asc' | 'desc') => unknown;
  };
};

interface Props<D> {
  data: D[];
  columns: TableColumns<D>;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  rowClassName?: string;
  cellClassName?: string;
  page?: number;
  pagesTotal?: number;
  isBusy?: boolean;
  onRowClick?: (data: D) => unknown;
  onPageChange?: (page: number) => unknown;
  onRowsOrderChange?: (orderIds: string[], isDirty: boolean) => unknown;
}

function Table<D extends { _id: string }>(props: Props<D>) {
  const {
    className = '',
    rowClassName = '',
    cellClassName = '',
    headerClassName = '',
    contentClassName = '',
    data,
    columns,
    onPageChange,
    onRowsOrderChange,
  } = props;
  const [rows, setRows] = React.useState<D[]>(data);
  const [orderIds, setOrderIds] = React.useState<string[]>(data.map((item) => item._id));
  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
  const [isDragging, setIsDragging] = React.useState(false);
  const isSortable = !!onRowsOrderChange;

  const onPaginationChange = React.useCallback(
    (e: React.ChangeEvent<unknown>, page: number) => {
      if (onPageChange) onPageChange(page);
    },
    [onPageChange],
  );

  const onItemDragStateChange = React.useCallback((isDragging: boolean) => {
    setIsDragging(isDragging);
  }, []);

  const onRowClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { index } = e.currentTarget.dataset;

      setSelectedIndex(Number(index));
      props.onRowClick?.(rows[Number(index)]);
    },
    [rows],
  );

  const dragRow = React.useCallback((dragIndex: number, hoverIndex: number) => {
    setSelectedIndex(hoverIndex);
    setRows((prev) => {
      const next = [...prev];

      next.splice(dragIndex, 1);
      next.splice(hoverIndex, 0, prev[dragIndex]);

      return next;
    });
  }, []);

  React.useEffect(() => {
    setRows(data);
    setOrderIds(data.map((item) => item._id));
  }, [data]);

  React.useEffect(() => {
    setSelectedIndex(-1);
  }, [props.page]);

  React.useEffect(() => {
    const newOrderIds = rows.map((item) => item._id);
    const isDirty = newOrderIds.some((id, index) => id !== orderIds[index]);

    onRowsOrderChange?.(newOrderIds, isDirty);
  }, [rows]);

  const renderHeader = () => {
    const hasNonEmptyCells = Object.entries(columns).some(([, value]) => {
      if (typeof value.header === 'function') {
        return !!value.header();
      }
      return !!value.header;
    });

    if (!hasNonEmptyCells) {
      return null;
    }

    return (
      <div className={cn(css.header, headerClassName)}>
        {Object.entries(columns).map(([key, column]) => {
          return (
            column && (
              <div className={cn(css.cell, props.cellClassName, column?.className)} key={key}>
                <div
                  className={cn(css.cellContentWrap, column.onSort && css.clickable)}
                  onClick={() => column.onSort?.(toggleSort(column.currentSort))}
                >
                  {typeof column.header === 'function' ? (
                    column.header()
                  ) : (
                    <Typography className={css.label}>{column.header}</Typography>
                  )}
                  {column.onSort && column.currentSort && (
                    <IconButton className={cn(css.sortBtn, column.currentSort && css.active)} size="small">
                      {column.currentSort === 'desc' ? (
                        <ArrowDownwardIcon fontSize="small" />
                      ) : (
                        <ArrowUpwardIcon fontSize="small" />
                      )}
                    </IconButton>
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };

  const renderRows = () => {
    const noDataLabel = 'No data available';

    if (!data || !Object.keys(data).length) return <Typography className={css.noDataLabel}>{noDataLabel}</Typography>;

    return <div className={cn(css.content, contentClassName)}>{rows.map(renderRow)}</div>;
  };

  const renderRow = (rowData: D, index: number) => {
    const commonProps = {
      className: cn(css.row, selectedIndex === index && css.selected, rowClassName),
      index: index,
      selected: selectedIndex === index,
      onClick: onRowClick,
    };
    const content = Object.entries(columns).map(
      ([key, column]) =>
        column && (
          <div className={cn(css.cell, cellClassName, column?.className)} key={key}>
            {column.render(rowData)}
          </div>
        ),
    );

    return isSortable ? (
      <SortableRow
        {...commonProps}
        id={rowData._id}
        key={rowData._id}
        onDrag={dragRow}
        onDragStateChange={onItemDragStateChange}
      >
        {content}
      </SortableRow>
    ) : (
      <Row {...commonProps} key={rowData._id}>
        {content}
      </Row>
    );
  };

  const renderFooter = () => {
    if (props.page === undefined || (props.pagesTotal || 0) <= 1) {
      return null;
    }
    return (
      <div className={css.footer}>
        <Pagination count={props.pagesTotal} page={props.page} onChange={onPaginationChange} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ overflow: 'auto' }}>
          <Box className={cn(css.table, isDragging && css.dragging, props.isBusy && css.busy, className)}>
            {renderHeader()}
            {renderRows()}
          </Box>
        </Box>
        {props.isBusy && <CircularProgress className={css.loader} />}
        {renderFooter()}
      </Box>
    );
  };

  return (
    <NoSsr>{isSortable ? <DndProvider backend={HTML5Backend}>{renderContent()}</DndProvider> : renderContent()}</NoSsr>
  );
}

function toggleSort(currentSort: 'asc' | 'desc' | undefined) {
  if (currentSort === 'asc') return 'desc';
  if (currentSort === 'desc') return undefined;
  return 'asc';
}

export default Table;
