import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';

import { ApiResponse } from '@api/types';
import { ROUTE, Params, Result } from '@api/routes/auth/session-out/params';

import { api } from 'src/utils/api';
import { app as firebaseApp } from 'src/firebase/client-app';

export const signOut = createAsyncThunk<ApiResponse<Result>, Params, { rejectValue: string }>(
  'admin/signOut',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.sendRequest<Result>({ url: ROUTE, method: 'POST', data });

      if (response.success) {
        await getAuth(firebaseApp).signOut();
        return response;
      }

      throw new Error('SignInError');
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
