import React from 'react';
import { Box, Button } from '@mui/material';
import Google from '@mui/icons-material/Google';
import Smartphone from '@mui/icons-material/Smartphone';

import { useAppSelector } from 'src/hooks/store';
import { selectAdmin } from 'src/store/admin/selectors';
import sx from './sx';

interface Props {
  onProviderClick: (e: React.MouseEvent<HTMLButtonElement>) => unknown;
}

const ProvidersList: React.FC<Props> = (props) => {
  const admin = useAppSelector(selectAdmin);
  const { onProviderClick } = props;
  const style = sx();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
      <Button
        data-id="google"
        sx={style.providerBtn}
        variant="contained"
        disabled={admin.auth.isInProgress}
        onClick={onProviderClick}
      >
        <Google sx={style.providerBtnIcon} />
        <span>Google</span>
      </Button>
      <Button
        data-id="phone"
        sx={style.providerBtn}
        variant="contained"
        disabled={admin.auth.isInProgress}
        onClick={onProviderClick}
      >
        <Smartphone sx={style.providerBtnIcon} />
        <span>Phone</span>
      </Button>
    </Box>
  );
};

export default ProvidersList;
