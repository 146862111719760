import { sx } from 'src/utils';

export default sx((theme) => ({
  subHeader: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',

    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      height: '2px',
      backgroundColor: theme?.palette.grey[100],
      opacity: theme?.palette.mode === 'dark' ? 0.15 : 1,
    },

    '& .MuiTabs-root': {
      position: 'relative',

      '& .MuiButtonBase-root': {
        paddingLeft: '10px',
        textAlign: 'left',
        alignItems: 'flex-start',
        marginRight: '20px',
      },
    },
  },

  subHeaderActions: {
    display: 'flex',
    gap: '10px',
    mr: '10px',

    '& .MuiButton-root': {
      height: '24px',
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
}));
