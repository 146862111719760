import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import format from 'date-fns/format';

import { routes } from 'src/constants';
import { SubHeader, SubHeaderActions, LoadingButton } from 'src/components/common';
import RestrictedContent, { SCOPE } from 'src/components/common/RestrictedContent';
import { data as dataApi } from 'src/store/api/data';
import { useAppDispatch } from 'src/hooks/store';
import { setAlertMessage } from 'src/store/ux';
import sx from './sx';

const Data = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const theme = useTheme();
  const style = sx(theme);
  const [currentTab, setCurrentTab] = useState(0);
  const tabs = [{ label: 'Data', id: 'data' }];
  const { data: metaData } = dataApi.endpoints.getMeta.useQuery();
  const [uploadData] = dataApi.endpoints.uploadData.useMutation();
  const dispatch = useAppDispatch();

  const onTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const onUploadClick = () => {
    inputRef.current?.click();
  };

  const onVideoFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsUploading(true);

    const result = await uploadData({ params: { data: file } }).unwrap();

    if (result.success) {
      dispatch(setAlertMessage({ severity: 'success', message: 'Data has been updated' }));
    } else {
      dispatch(setAlertMessage({ severity: 'error', message: 'Something went wrong.' }));
    }

    setIsUploading(false);

    if (inputRef.current!.files) {
      inputRef.current!.value = '';
    }
  };

  return (
    <RestrictedContent scope={[SCOPE.CONTENT.EDIT]} error={<Navigate to={routes.ROOT} replace />}>
      <Box sx={style.container}>
        <Typography variant="h1" fontWeight={100} sx={style.header}>
          Data
        </Typography>
        <SubHeader>
          <Tabs value={currentTab} onChange={onTabsChange}>
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>
          <SubHeaderActions>
            <LoadingButton
              loading={isUploading}
              variant="contained"
              size="small"
              onClick={onUploadClick}
              label="Upload"
            ></LoadingButton>
            <input type="file" onChange={onVideoFileChange} style={{ display: 'none' }} ref={inputRef} />
          </SubHeaderActions>
        </SubHeader>
        {tabs[currentTab].id === 'data' && (
          <Box>
            <Typography variant="body1" fontWeight={400}>
              {`Last update: ${
                metaData?.success ? format(new Date(metaData?.data.updatedAt), 'dd/MM/yyyy HH:mm:ss') : 'N/A'
              }`}
            </Typography>
            <Typography variant="body1" fontWeight={400}>
              {`Total records on website: ${metaData?.success ? metaData?.data.rows : 'N/A'}`}
            </Typography>
          </Box>
        )}
      </Box>
    </RestrictedContent>
  );
};

export default Data;
