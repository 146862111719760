import { sx } from 'src/utils';

export default sx((theme) => ({
  container: {
    position: 'relative',
    width: '600px',
  },
  doubleColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '20px',
  },
}));
