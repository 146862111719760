import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, signInWithCustomToken, inMemoryPersistence } from 'firebase/auth';
import cookies from 'js-cookie';

import { ApiResponse } from '@api/types';
import { CSRF_TOKEN } from '@api/constants/cookies';
import { ROUTE as AUTH_ROUTE, Params as AuthParams, Result as AuthResult } from '@api/routes/auth/admin/params';
import { ROUTE as SESSION_IN_ROUTE } from '@api/routes/auth/session-in/params';

import { api } from 'src/utils/api';
import { app as firebaseApp } from 'src/firebase/client-app';

export const signIn = createAsyncThunk<ApiResponse<AuthResult>, AuthParams, { rejectValue: string }>(
  'admin/signIn',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.sendRequest<AuthResult>({ url: AUTH_ROUTE, method: 'POST', data });

      if (response.success) {
        const firebaseAuth = getAuth(firebaseApp);

        await firebaseAuth.setPersistence(inMemoryPersistence);
        await signInWithCustomToken(firebaseAuth, response.data.token);

        const idToken = await firebaseAuth.currentUser?.getIdToken(/* forceRefresh */ true);
        const csrfToken = cookies.get(CSRF_TOKEN);

        if (idToken && csrfToken) {
          await api.sendRequest({ url: SESSION_IN_ROUTE, method: 'POST', data: { idToken, csrfToken } });
          await firebaseAuth.signOut();

          return response;
        }
      } else {
        throw new Error(response.error?.message as string);
      }
      throw new Error('SignInError');
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
