import React from 'react';

import { ROLE } from '@api/models/admin/role';
import { selectRole } from 'src/store/admin/selectors';
import { useAppSelector } from 'src/hooks/store';

interface Props {
  scope: string[];
  children: React.ReactNode;
  error?: React.ReactNode;
}

export const SCOPE = {
  CONTENT: {
    VIEW: 'CONTENT.VIEW',
    EDIT: 'CONTENT.EDIT',
    CREATE: 'CONTENT.CREATE',
    DELETE: 'CONTENT.DELETE',
  },
  USERS: {
    VIEW: 'USERS.VIEW',
    EDIT: 'USERS.EDIT',
    CREATE: 'USERS.CREATE',
    DELETE: 'USERS.DELETE',
  },
} as const;

export const PERMISSIONS = {
  [ROLE.CONTENT_VIEWER]: [SCOPE.CONTENT.VIEW],
  [ROLE.CONTENT_EDITOR]: [SCOPE.CONTENT.VIEW, SCOPE.CONTENT.EDIT],
  [ROLE.CONTENT_ADMIN]: Object.values(SCOPE.CONTENT),
  [ROLE.PRODUCT_ADMIN]: [...Object.values(SCOPE.CONTENT), ...Object.values(SCOPE.USERS)],
  [ROLE.DEVELOPER]: [...Object.values(SCOPE.CONTENT), ...Object.values(SCOPE.USERS)],
};

const RestrictedContent = (props: Props) => {
  const { children, scope, error } = props;
  const role = useAppSelector(selectRole);
  const hasAccess = checkAdminPermissions(role, scope);

  return <>{hasAccess ? children : error}</>;
};

export const checkAdminPermissions = (role: ROLE, scope: string[]) => {
  return PERMISSIONS[role] && PERMISSIONS[role].some((permission) => scope.includes(permission));
};

export default RestrictedContent;
