import { sx } from 'src/utils';

export default sx((theme) => ({
  container: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1250,
  },
  modal: {
    position: 'relative',
    padding: '40px',
    minWidth: '400px',
  },
  modalContent: {
    overflow: 'auto',
  },
  closeBtn: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    opacity: 0.5,
    transformOrigin: 'top right',
    transform: 'scale(0.75)',

    '&:hover': {
      opacity: 1,
    },
  },
}));
