import { sx } from 'src/utils';

export default sx((theme) => ({
  mediaField: {
    position: 'relative',
    width: '160px',
    height: '160px',
    borderRadius: '10px',
    backgroundColor: 'transparent',

    '& label': {
      position: 'absolute',
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,

      '& .MuiSvgIcon-root': {
        opacity: 0.5,
      },
    },

    '& video': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 1,
    },

    '& input': {
      display: 'none',
    },

    '&:hover': {
      borderColor: theme?.palette.mode === 'dark' ? theme?.palette.grey[100] : theme?.palette.grey[800],

      '& .MuiSvgIcon-root': {
        opacity: 1,
      },
    },
  },
  withMedia: {
    '& .MuiSvgIcon-root': {
      visibility: 'hidden',
    },
  },
}));
