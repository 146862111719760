import React from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Divider, Typography, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { routes } from 'src/constants';
import { RabbiLogo } from 'src/components/common';
import { useAppSelector } from 'src/hooks/store';
import { selectAdmin } from 'src/store/admin/selectors';

import Providers from './Providers';
import sx from './sx';

const Login = () => {
  const admin = useAppSelector(selectAdmin);
  const theme = useTheme();
  const style = sx(theme);

  if (admin.auth.isLoggedIn) {
    return <Navigate to={routes.ROOT} replace />;
  }

  return (
    <Box sx={style.container}>
      <Box sx={style.poster} />
      <Box sx={style.sidebar}>
        <Box sx={style.logoWrap}>
          <RabbiLogo />
        </Box>
        <Typography variant="h2" fontWeight={400} sx={{ mb: '10px' }}>
          {text.title}
        </Typography>
        <Typography variant="h5" fontWeight={400}>
          {text.subtitle}
        </Typography>
        {admin.auth.isInProgress ? <LinearProgress sx={{ m: '38px 0 39px' }} /> : <Divider sx={{ my: '40px' }} />}
        <Providers />
        <Box sx={style.footer}>
          <Typography variant="body1">{text.credits}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const text = {
  title: 'Sign In',
  subtitle: `Only authorized users can login to the dashboard.
When you try to connect, you authorize us to save your IP address and identity information data to verify you.`,
  credits: `Rabbi Interactive Agency ${new Date().getFullYear()}`,
};

export default Login;
