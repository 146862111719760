import { sx } from 'src/utils';

export default sx((theme) => ({
  container: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  },

  containerLoading: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },

  label: {
    color: 'inherit',
  },
}));
