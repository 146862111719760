import React from 'react';
import { Route, Navigate, Outlet, createRoutesFromElements } from 'react-router-dom';

import { ROOT, SETTINGS_ADMINS, SETTINGS_DATA, LOGIN } from 'src/constants/routes';
import { PrivateRoute, Head, Snackbar } from 'src/components/common';
import { ThemeWrapper } from 'src/Theme';
import PageLayout from 'src/components/PageLayout';
import Admins from 'src/components/pages/Settings/Admins';
import Data from 'src/components/pages/Settings/Data';
import Login from 'src/components/pages/Login';

const privatePage = (page: React.ReactNode) => <PrivateRoute>{page}</PrivateRoute>;

export const Routes = (
  <>
    <Route path={ROOT} element={<PageLayout />}>
      <Route path={ROOT} element={<Navigate to={SETTINGS_DATA} replace />} />
      <Route path={SETTINGS_DATA} element={privatePage(<Data />)} />
      <Route path={SETTINGS_ADMINS} element={privatePage(<Admins />)} />
    </Route>
    <Route
      path={LOGIN}
      element={
        <ThemeWrapper>
          <Snackbar />
          <Head />
          <Outlet />
        </ThemeWrapper>
      }
    >
      <Route index element={<Login />} />
    </Route>
  </>
);

export const routes = createRoutesFromElements(Routes);

export default routes;
