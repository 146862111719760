import qs from 'qs';

import { API_HOST } from 'src/utils/api';
import {
  ROUTE as PAGINATE_ROUTE,
  Params as PaginateParams,
  Result as PaginateResult,
} from '@api/routes/admins/paginate/params';
import {
  ROUTE as CREATE_ROUTE,
  Params as CreateParams,
  Result as CreateResult,
} from '@api/routes/admins/create/params';
import {
  ROUTE as UPDATE_ROUTE,
  Params as UpdateParams,
  Result as UpdateResult,
} from '@api/routes/admins/update/params';
import {
  ROUTE as DELETE_ROUTE,
  Params as DeleteParams,
  Result as DeleteResult,
} from '@api/routes/admins/delete/params';
import { ApiResponse } from '@api/types';
import { renameFile } from 'src/utils/rename-file';
import api from '..';

export const admins = api.injectEndpoints({
  endpoints: (builder) => ({
    paginateAdmins: builder.query<ApiResponse<PaginateResult>, PaginateParams>({
      query: (params) => ({
        method: 'GET',
        url: `${PAGINATE_ROUTE}?${qs.stringify(params)}`,
      }),
      providesTags: (result) =>
        result?.success
          ? [...result.data.items.map(({ _id }) => ({ type: 'ADMIN', _id } as const)), { type: 'ADMIN', id: 'ALL' }]
          : [{ type: 'ADMIN', id: 'ALL' }],
    }),
    createAdmin: builder.mutation<ApiResponse<CreateResult>, { params: CreateParams }>({
      queryFn: async ({ params }) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          const request = new XMLHttpRequest();

          request.withCredentials = true;

          if (params.email) formData.append('email', params.email);
          if (params.name) formData.append('name', params.name);
          if (params.phone) formData.append('phone', params.phone);
          if (params.role) formData.append('role', params.role);
          if (params.picture) formData.append('picture', renameFile(params.picture as File, 'picture'));

          request.onload = () => {
            try {
              const response = JSON.parse(request.response);
              resolve({
                data: response as ApiResponse<CreateResult>,
              });
            } catch (e) {
              reject(e);
            }
          };
          request.open('POST', `${API_HOST}${CREATE_ROUTE}`);
          request.send(formData);
        });
      },
      invalidatesTags: () => [{ type: 'ADMIN', id: 'ALL' }],
    }),
    updateAdmin: builder.mutation<ApiResponse<UpdateResult>, { id: string; params: UpdateParams }>({
      queryFn: async ({ id, params }) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          const request = new XMLHttpRequest();

          request.withCredentials = true;

          if (params.email) formData.append('email', params.email);
          if (params.name) formData.append('name', params.name);
          if (params.phone) formData.append('phone', params.phone);
          if (params.role) formData.append('role', params.role);
          if (params.picture) formData.append('picture', renameFile(params.picture as File, 'picture'));

          request.onload = () => {
            try {
              const response = JSON.parse(request.response);
              resolve({
                data: response as ApiResponse<UpdateResult>,
              });
            } catch (e) {
              reject(e);
            }
          };
          request.open('POST', `${API_HOST}${UPDATE_ROUTE.replace(':id', id)}`);
          request.send(formData);
        });
      },
      invalidatesTags: () => [{ type: 'ADMIN', id: 'ALL' }],
    }),
    deleteAdmin: builder.mutation<ApiResponse<DeleteResult>, string>({
      query: (id) => ({
        method: 'DELETE',
        url: DELETE_ROUTE.replace(':id', id),
      }),
      invalidatesTags: () => [{ type: 'ADMIN', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
