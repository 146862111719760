import React from 'react';
import ReactDOMClient, { createRoot } from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter, Routes } from 'react-router-dom';
import history from 'history/browser';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import { store } from 'src/store';
import { Routes as AppRoutes } from 'src/routes';

const container = document.getElementById('root');

const Wrap = (
  <HelmetProvider>
    <Provider store={store}>
      <HistoryRouter history={history as any}>
        <Routes>{AppRoutes}</Routes>
      </HistoryRouter>
    </Provider>
  </HelmetProvider>
);

if (container) {
  if (process.env.ADMIN_SSR) {
    ReactDOMClient.hydrateRoot(container, Wrap);
  } else {
    createRoot(container).render(Wrap);
  }
} else {
  throw new Error('No #root element in DOM');
}
