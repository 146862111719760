import React, { useMemo } from 'react';
import { useField } from 'formik';
import { Box, Typography, useTheme } from '@mui/material';
import { Select as MuiSelect, SelectProps, FormControl, InputLabel, MenuItem, FormHelperText } from '@mui/material';

import sx from './sx';

interface Props extends SelectProps {
  name: string;
  label: string;
  options: { value: string; label: string }[];
}

const Select = (props: Props) => {
  const { name, label, options } = props;
  const [field, meta] = useField(name);
  const theme = useTheme();
  const style = sx(theme);
  const hasErrorToShow = !!(meta.touched && meta.error);

  const helperText = useMemo(() => {
    return Array.isArray(meta.error)
      ? (meta.error as string[]).map((error, index) => (
          <Typography sx={style.errorMessage} key={`${error}-${index}`}>
            {error}
          </Typography>
        ))
      : meta.error;
  }, [meta.error]);

  return (
    <Box
      sx={{
        ...style.select,
        ...(props.sx || {}),
      }}
    >
      <FormControl error={hasErrorToShow} size={props.size}>
        <InputLabel id={label}>{label}</InputLabel>
        <MuiSelect {...field} labelId={label} label={label} fullWidth={props.fullWidth}>
          {options.map((option) => (
            <MenuItem sx={{ zIndex: 9999999 }} key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
        {hasErrorToShow && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default Select;
