import { sx } from 'src/utils';
import { gridStyles } from 'src/styles/sx';

export default sx((theme) => ({
  container: {
    position: 'relative',
  },

  header: {
    mb: '30px',
  },

  grid: gridStyles(theme),

  actionBtn: {
    fontSize: '14px',
    height: '24px',
  },
}));
