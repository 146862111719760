import { sx } from 'src/utils';

export default sx(() => ({
  container: {
    position: 'relative',
  },

  themeToggleBtn: {
    position: 'absolute',
    zIndex: 10,
    top: '30px',
    right: '20px',
  },

  content: {
    padding: '20px 15px',
    display: 'flex',
    alignItems: 'center',
  },

  avatar: {
    marginRight: '12px',
  },

  nameAndActions: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
  },

  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
