import React from 'react';
import { Box, Divider, Avatar, Typography, IconButton, CircularProgress, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';

import { useAppSelector, useAppDispatch } from 'src/hooks/store';
import { signOut } from 'src/store/admin/actions/sign-out';
import { selectAuth, selectName, selectPictureUrl, selectShortName } from 'src/store/admin/selectors';
import sx from './sx';

interface Props {
  sx?: SxProps;
}

const AdminUser = (props: Props) => {
  const dispatch = useAppDispatch();
  const pictureUrl = useAppSelector(selectPictureUrl);
  const auth = useAppSelector(selectAuth);
  const name = useAppSelector(selectName);
  const shortName = useAppSelector(selectShortName);
  const theme = useTheme();
  const style = sx(theme);

  const onLogoutClick = () => {
    dispatch(signOut());
  };

  return (
    <Box sx={{ ...style.container, ...props.sx }}>
      <Divider />
      <Box sx={style.content}>
        <Avatar alt={name} src={pictureUrl} sx={style.avatar}>
          {shortName}
        </Avatar>
        <Box sx={style.nameAndActions}>
          <Typography variant="body2" fontWeight={900}>
            {name}
          </Typography>
          <Box sx={style.actions}>
            <IconButton onClick={onLogoutClick} disabled={auth.isInProgress}>
              {auth.isInProgress === false ? <LogoutIcon /> : <CircularProgress color="inherit" size={20} />}
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminUser;
