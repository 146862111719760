import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Button, Tabs, Tab, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { IModel } from '@api/models/admin/interface';
import { routes } from 'src/constants';
import { SubHeader, SubHeaderActions } from 'src/components/common';
import RestrictedContent, { SCOPE } from 'src/components/common/RestrictedContent';
import { Modal } from 'src/components/common';
import Details from './Details';
import List from './List';
import sx from './sx';

const Admins = () => {
  const [selectedAccountData, setSelectedAccountData] = React.useState<IModel | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const theme = useTheme();
  const style = sx(theme);
  const [currentTab, setCurrentTab] = useState(0);
  const tabs = [{ label: 'Accounts', id: 'list' }];

  const onTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const onAddAccount = () => {
    setIsEditModalOpen(true);
  };

  const onAccountEditClick = (accountData: IModel) => {
    setSelectedAccountData(accountData);
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setSelectedAccountData(null);
    setIsEditModalOpen(false);
  };

  return (
    <RestrictedContent scope={[SCOPE.USERS.EDIT]} error={<Navigate to={routes.ROOT} replace />}>
      <Box sx={style.container}>
        <Typography variant="h1" fontWeight={100} sx={style.header}>
          Administrators
        </Typography>
        <SubHeader>
          <Tabs value={currentTab} onChange={onTabsChange}>
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>
          <SubHeaderActions>
            <Button variant="contained" size="small" onClick={onAddAccount}>
              Add account
            </Button>
          </SubHeaderActions>
        </SubHeader>
        {tabs[currentTab].id === 'list' && <List onAccountEditClick={onAccountEditClick} />}
      </Box>
      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Details accountData={selectedAccountData} />
      </Modal>
    </RestrictedContent>
  );
};

export default Admins;
