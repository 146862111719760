import React, { useState, useEffect, useRef } from 'react';
import { Card, SxProps } from '@mui/material';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { useTheme } from '@mui/material/styles';

import sx from './sx';

interface Props {
  sx?: SxProps;
  name?: string;
  value?: string | ArrayBuffer | null | undefined;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

const MediaField = (props: Props) => {
  const { name, disabled } = props;
  const [media, setMedia] = useState<string | ArrayBuffer | null | undefined>(props.value);
  const inputRef = useRef<HTMLInputElement>(null);
  const style = sx(useTheme());

  let mediaType = typeof media === 'string' && media.includes('.mp4') ? 'video' : 'image';
  if (inputRef.current?.files && inputRef.current.files[0]) {
    mediaType = inputRef.current.files[0].name.includes('.mp4') ? 'video' : 'image';
  }

  const onChange = (e: React.ChangeEvent<any>) => {
    const reader = new FileReader();

    reader.onloadend = () => setMedia(reader.result);
    reader.readAsDataURL(e.currentTarget.files[0]);
    if (props.onChange) props.onChange(e);
  };

  useEffect(() => {
    setMedia(props.value);
  }, [props.value]);

  return (
    <Card
      sx={{
        ...style.mediaField,
        ...(media && style.withMedia),
        ...(disabled && { pointerEvents: 'none' }),
        ...props.sx,
      }}
      variant="outlined"
    >
      <label style={media && mediaType === 'image' ? { backgroundImage: `url(${media})` } : {}}>
        <input type="file" name={name} onChange={onChange} ref={inputRef} />
        <AddToPhotosIcon />
      </label>
      {media && mediaType === 'video' && (
        <video>
          <source
            src={
              inputRef.current?.files && inputRef.current.files[0]
                ? URL.createObjectURL(inputRef.current.files[0])
                : (media as string)
            }
          />
        </video>
      )}
    </Card>
  );
};

export default MediaField;
