import React, { ReactNode } from 'react';
import { useLocation, matchRoutes, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemText, Typography, Divider } from '@mui/material';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import { useTheme } from '@mui/material/styles';

import { routes } from 'src/constants/index';
import { useAppSelector } from 'src/hooks/store';
import { selectRole } from 'src/store/admin/selectors';
import { checkAdminPermissions, SCOPE } from 'src/components/common/RestrictedContent';

const NAVIGATION = [
  {
    section: 'Settings',
    routes: [
      { route: routes.SETTINGS_DATA, label: 'Data', scope: [SCOPE.CONTENT.EDIT] },
      { route: routes.SETTINGS_ADMINS, label: 'Administrators', scope: [SCOPE.USERS.EDIT] },
    ],
  },
];

const ROUTES = [{ path: routes.SETTINGS_DATA }, { path: routes.SETTINGS_ADMINS }];

const Navigation = () => {
  const location = useLocation();
  const currentRoute = matchRoutes(ROUTES, location)?.[0].route.path;
  const role = useAppSelector(selectRole);
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <List sx={{ color: theme.palette.primary[100] }}>
      {NAVIGATION.map((section) => {
        const result: ReactNode[] = [];

        if (section.section) {
          result.push(<Divider key={`${section.section}-divider`} sx={{ mt: '20px', mb: '30px' }} />);
          result.push(
            <Typography key={section.section} variant="h6" sx={{ m: '16px 10px 10px 16px', opacity: 0.5 }}>
              {section.section}
            </Typography>,
          );
        }

        result.push(
          ...section.routes.map((route) => {
            const isMathced = currentRoute?.includes(route.route);
            const bgColor = theme.palette.mode === 'dark' ? theme.palette.primary[200] : theme.palette.secondary[500];
            const bgColorHover =
              theme.palette.mode === 'dark' ? theme.palette.primary[100] : theme.palette.secondary[600];

            if ('scope' in route && route.scope && !checkAdminPermissions(role, route.scope)) {
              return null;
            }

            return (
              <ListItem key={`${section.section}-${route.route}-route`} disablePadding>
                <ListItemButton
                  sx={{
                    backgroundColor: isMathced ? bgColor : 'transparent',
                    color: isMathced ? theme.palette.secondary[900] : 'inherit',
                    '&:hover': {
                      backgroundColor: isMathced ? bgColorHover : '',
                    },
                  }}
                  onClick={() => navigate(route.route)}
                >
                  <ListItemText disableTypography primary={<Typography fontWeight="bold">{route.label}</Typography>} />
                  {isMathced && <ChevronRightOutlined sx={{ ml: 'auto' }} />}
                </ListItemButton>
              </ListItem>
            );
          }),
        );

        return result;
      })}
    </List>
  );
};

export default Navigation;
