import React from 'react';

type Props = {
  mode: 'light' | 'dark';
};

const MakoLogo = (props: Props) => {
  const { mode } = props;
  const scale = 0.65;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={155 * scale}
      height={54 * scale}
      viewBox="0 0 155 54"
    >
      <defs>
        <path id="a" d="M0 .08h154.892v26.868H0z" />
        <path
          id="c"
          d="M7.5 9.284C-2.25 15.188-.01 26.223 1.04 31.168c.951 4.484 3.806 18.297 11.605 21.612 6.86 2.917 19.173-.487 22.49-1.753 3.317-1.27 13.064-5.571 16.322-11.614C57.035 29.075 46.216 4.988 36.995.986c-1.4-.608-3.012-.873-4.756-.873-7.952 0-18.676 5.503-24.738 9.171"
        />
        <path
          id="e"
          d="M7.5 9.284C-2.25 15.188-.01 26.223 1.04 31.168c.951 4.484 3.806 18.297 11.605 21.612 6.86 2.917 19.173-.487 22.49-1.753 3.317-1.27 13.064-5.571 16.322-11.614C57.035 29.075 46.216 4.988 36.995.986c-1.4-.608-3.012-.873-4.756-.873-7.952 0-18.676 5.503-24.738 9.171"
        />
        <path
          id="g"
          d="M7.5 9.284C-2.25 15.188-.01 26.223 1.04 31.168c.951 4.484 3.806 18.297 11.605 21.612 6.86 2.917 19.173-.487 22.49-1.753 3.317-1.27 13.064-5.571 16.322-11.614C57.035 29.075 46.216 4.988 36.995.986c-1.4-.608-3.012-.873-4.756-.873-7.952 0-18.676 5.503-24.738 9.171"
        />
        <path
          id="i"
          d="M7.5 9.284C-2.25 15.188-.01 26.223 1.04 31.168c.951 4.484 3.806 18.297 11.605 21.612 6.86 2.917 19.173-.487 22.49-1.753 3.317-1.27 13.064-5.571 16.322-11.614C57.035 29.075 46.216 4.988 36.995.986c-1.4-.608-3.012-.873-4.756-.873-7.952 0-18.676 5.503-24.738 9.171"
        />
        <path
          id="k"
          d="M7.5 9.284C-2.25 15.188-.01 26.223 1.04 31.168c.951 4.484 3.806 18.297 11.605 21.612 6.86 2.917 19.173-.487 22.49-1.753 3.317-1.27 13.064-5.571 16.322-11.614C57.035 29.075 46.216 4.988 36.995.986c-1.4-.608-3.012-.873-4.756-.873-7.952 0-18.676 5.503-24.738 9.171"
        />
        <path
          id="m"
          d="M7.5 9.284C-2.25 15.188-.01 26.223 1.04 31.168c.951 4.484 3.806 18.297 11.605 21.612 6.86 2.917 19.173-.487 22.49-1.753 3.317-1.27 13.064-5.571 16.322-11.614C57.035 29.075 46.216 4.988 36.995.986c-1.4-.608-3.012-.873-4.756-.873-7.952 0-18.676 5.503-24.738 9.171"
        />
        <path
          id="o"
          d="M7.5 9.284C-2.25 15.188-.01 26.223 1.04 31.168c.951 4.484 3.806 18.297 11.605 21.612 6.86 2.917 19.173-.487 22.49-1.753 3.317-1.27 13.064-5.571 16.322-11.614C57.035 29.075 46.216 4.988 36.995.986c-1.4-.608-3.012-.873-4.756-.873-7.952 0-18.676 5.503-24.738 9.171"
        />
        <path
          id="q"
          d="M7.5 9.284C-2.25 15.188-.01 26.223 1.04 31.168c.951 4.484 3.806 18.297 11.605 21.612 6.86 2.917 19.173-.487 22.49-1.753 3.317-1.27 13.064-5.571 16.322-11.614C57.035 29.075 46.216 4.988 36.995.986c-1.4-.608-3.012-.873-4.756-.873-7.952 0-18.676 5.503-24.738 9.171"
        />
        <path
          id="s"
          d="M7.5 9.284C-2.25 15.188-.01 26.223 1.04 31.168c.951 4.484 3.806 18.297 11.605 21.612 6.86 2.917 19.173-.487 22.49-1.753 3.317-1.27 13.064-5.571 16.322-11.614C57.035 29.075 46.216 4.988 36.995.986c-1.4-.608-3.012-.873-4.756-.873-7.952 0-18.676 5.503-24.738 9.171"
        />
        <path
          id="u"
          d="M7.5 9.284C-2.25 15.188-.01 26.223 1.04 31.168c.951 4.484 3.806 18.297 11.605 21.612 6.86 2.917 19.173-.487 22.49-1.753 3.317-1.27 13.064-5.571 16.322-11.614C57.035 29.075 46.216 4.988 36.995.986c-1.4-.608-3.012-.873-4.756-.873-7.952 0-18.676 5.503-24.738 9.171z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 14)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill={mode === 'light' ? '#231F20' : '#fff'}
            d="M61.718 19.895c-1.791-1.534-1.44-5.341 6.134-4.705.533 5.696-4.563 6.047-6.134 4.705M52.041 1.723V8.81c11.42-4.482 16.441-.635 15.8 1.561C46.07 7.25 46.403 22.476 52.586 25.562c6.38 3.185 12.883.295 15.2-2.89v3.639h10.96s.891-18.466-2.004-22.433C73.152-1.045 57.47-.385 52.041 1.723m86.178 17.823c-2.169 0-2.81-2.994-2.81-6.326 0-3.39.389-5.764 2.746-5.708 2.301-.177 2.935 2.196 2.935 5.708 0 3.934-.885 6.326-2.87 6.326M138.674.08c-8.283 0-17.323 2.3-17.323 13.153 0 10.735 8.655 13.802 17.323 13.714 5.334 0 16.217-2.332 16.217-13.714 0-10.454-7.92-13.153-16.217-13.153M38.092.142c-5.287 0-8.097 2.916-8.097 4.64 0-1.844-2.727-4.64-8.669-4.64-6.948 0-8.622 4.356-8.94 5.237V.68H0v25.665h13.087V12.49c0-2.069-.194-4.366 2.262-4.366 2.58 0 2.261 2.216 2.261 4.366v13.856h12.948V12.49c0-2.35.074-4.366 2.222-4.366 2.636 0 2.223 2.707 2.223 4.366v13.856h12.849V12.169c0-4.815 1.533-12.027-9.76-12.027"
            mask="url(#b)"
          />
        </g>
        <g transform="translate(75)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            fill="#52347D"
            d="M49.264 16.004c3.714-7.136 7.812 29.284 1.917 25.796-6.139-3.632-14.06-13.959-18.7-14.784-4.61-.82 13.069-3.875 16.783-11.012"
            mask="url(#d)"
          />
        </g>
        <g transform="translate(75)">
          <mask id="f" fill="#fff">
            <use xlinkHref="#e" />
          </mask>
          <path
            fill="#414992"
            d="M34.057 26.06c6.725-.504 14.387-3.885 17.569-9.877.984-1.854-3.616-7.877-4.575-9.502-9.083-15.4-16.142 19.64-15.635 19.86 4.046 1.75 10.202 11.469 12.895 20.165.584 1.886 5.836-3.303 7.523-4.353-2.567-7.425-11.893-16.038-17.458-16.215.752.086-.319-.079-.319-.079"
            mask="url(#f)"
          />
        </g>
        <g transform="translate(75)">
          <mask id="h" fill="#fff">
            <use xlinkHref="#g" />
          </mask>
          <path
            fill="#246BA8"
            d="M31.568 26.452c6.473-1.072 12.5-10.094 14.208-17.477-2.485-3.725-9.68-10.843-9.402-7.667.684 7.846 1.876 18.537-5.005 25.294-.216.212.18.6.35.86 7.042 10.763 6.504 24.15 5.645 24.14-.218-.002.99.124 1.495-.225 1.588-1.093 5.89-3.917 6.996-4.753-2.96-8.777-10.16-18.623-14.287-20.172"
            mask="url(#h)"
          />
        </g>
        <g transform="translate(75)">
          <mask id="j" fill="#fff">
            <use xlinkHref="#i" />
          </mask>
          <path
            fill="#16A9C9"
            d="M37.264-1.432c1.93 9.589 1.184 21.81-5.611 27.956 5.724 4.735 8.188 24.906 7.107 25.716-1.08.81-3.675.665-7.168 2.16-.18-15.1.47-23.817-.356-28.16.813-1.551 3.097-23.704-5.242-27.822 2.333-1.227 12.235-.866 11.27.15"
            mask="url(#j)"
          />
        </g>
        <g transform="translate(75)">
          <mask id="l" fill="#fff">
            <use xlinkHref="#k" />
          </mask>
          <path
            fill="#56BB73"
            d="M30.629-1.624c2.011 8.96 3.985 22.443.761 27.937-.063.107-.1.194-.056.318 1.803 5.1 2.066 15.192.375 26.581-.186 1.25 1.014 1.74-10.995 3.239-.211.026-.85-.63-.926-.542 5.718-6.653 9.818-16.079 10.01-26.602C30.235 5.524 18.404 2.46 21.325.872c5.322-2.893 6.84-6.676 7.312-5.028.055.191 1.946 2.322 1.993 2.532"
            mask="url(#l)"
          />
        </g>
        <g transform="translate(75)">
          <mask id="n" fill="#fff">
            <use xlinkHref="#m" />
          </mask>
          <path
            fill="#FDD40D"
            d="M23.997.149c12.607 18.288 6.588 44.966-3.246 56.597-3.669 4.34-10.504-2.931-9.213-4.02 9.796-8.266 21.827-21.097 14.066-37.153-1.28-2.647-5.727-7.125-8.77-10.876-.532-.656-3.901-.616 3.65-4.561.82-.428 2.24-1.834 3.513.013"
            mask="url(#n)"
          />
        </g>
        <g transform="translate(75)">
          <mask id="p" fill="#fff">
            <use xlinkHref="#o" />
          </mask>
          <path
            fill="#F9A729"
            d="M24.766 12.527c11.327 17.1-4.237 32.42-12.64 40.739-.043-.015-.283.052-1.395-.491-1.866-.857-4.13-5.13-5.618-6.906 8.718-4.626 27.722-13.172 19.064-27.176-2.192-3.544-7.542-7.477-14.238-10.62-.18-.085-.048-.173-.146-.342-1.226-2.11 6.068-4.96 6.887-4.058.173.19.311.43.518.591 2.464 1.916 5.178 4.654 7.568 8.263"
            mask="url(#p)"
          />
        </g>
        <g transform="translate(75)">
          <mask id="r" fill="#fff">
            <use xlinkHref="#q" />
          </mask>
          <path
            fill="#F26F21"
            d="M4.023 12.407c6.11 2.299 14.192 4.697 16.238 8.811C25.62 31.994-3.262 28.016.436 36.491c.927 2.126 2.958 11.135 5.042 10.058 14.181-7.324 22.003-15.135 21.213-22.023-.999-8.701-10.041-14.25-16.961-17.179-9.254-3.916-9.891 3.486-5.707 5.06"
            mask="url(#r)"
          />
        </g>
        <g transform="translate(75)">
          <mask id="t" fill="#fff">
            <use xlinkHref="#s" />
          </mask>
          <path
            fill="#D91F26"
            d="M-.36 38.087c4.36-1.111-.743.21 8.02-2.056 9.05-2.34 20.032-9.285 12.071-16.318-5.388-4.761-10.79-6.444-16.003-7.591-6.43 6.378-6.692 10.65-5.41 15.142C-.4 31.756-.36 38.087-.36 38.087"
            mask="url(#t)"
          />
        </g>
        <g transform="translate(75)">
          <mask id="v" fill="#fff">
            <use xlinkHref="#u" />
          </mask>
          <path
            fill="#FEFEFE"
            d="M10.62 5.626v35.621h12.956l.088-13.016 7.462 13.016H45.94l-9.068-14.86 9.142-11.72H31.126l-7.53 10.642V-.2z"
            mask="url(#v)"
          />
        </g>
      </g>
    </svg>
  );
};

export default MakoLogo;
