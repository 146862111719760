import { sx } from 'src/utils';

export default sx((theme) => ({
  search: {
    position: 'relative',
    margin: '10px auto 0',
    textAlign: 'center',
    width: '500px',

    '> div': {
      width: 'inherit',
    },
  },
}));
