import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Drawer, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { routes } from 'src/constants';
import { MakoLogo } from 'src/components/common';
import Navigation from './Navigation';
import Account from './Account';

const SideBar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const theme = useTheme();
  const width = 300;

  const onMenuToggleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box component="aside" height="100vh">
      {isOpen && (
        <Drawer
          open={isOpen}
          variant="persistent"
          anchor="left"
          onClose={() => setIsOpen(false)}
          sx={{
            width: `${width}px`,
            '& .MuiDrawer-paper': {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: 'border-box',
              borderWidth: isMobile ? '2px' : 0,
              width: `${width}px`,
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ m: '20px 20px 20px 10px' }}>
                <Box display="flex" alignItems="center" gap="10px">
                  <IconButton onClick={onMenuToggleClick}>
                    <MenuIcon />
                  </IconButton>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                    <Link to={routes.ROOT}>
                      <MakoLogo mode={theme.palette.mode} />
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Navigation />
            </Box>
            <Account />
          </Box>
        </Drawer>
      )}
      <Box width="58px" height="100%">
        <Box
          sx={{
            width: '58px',
            height: '100%',
            position: 'fixed',
            left: 0,
            top: 0,
            backgroundColor: theme.palette.background.alt,
            p: '20px 20px 20px 10px',
          }}
        >
          <IconButton onClick={onMenuToggleClick}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SideBar;
