import React from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import { signIn } from 'src/store/admin/actions/sign-in';
import { useAppDispatch } from 'src/hooks/store';
import { setAlertMessage } from 'src/store/ux';

type Props = {
  onError: () => void;
};
const GoogleProvider: React.FC<Props> = (props) => {
  const appDispatch = useAppDispatch();

  React.useEffect(() => {
    const init = async () => {
      try {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();

        // optional:
        // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

        const result = await signInWithPopup(auth, provider);
        const { user } = result;

        if (user?.email) {
          const result = await appDispatch(signIn({ provider: 'google', email: user.email }));

          if (result.meta.requestStatus === 'rejected') {
            appDispatch(setAlertMessage({ severity: 'error', message: result.payload as string }));
            props.onError();
          }
        }
      } catch (error) {
        console.log('Unable to get user data');
        props.onError();
      }
    };

    init();
  }, [appDispatch]);

  return null;
};

export default GoogleProvider;
