import { sx } from 'src/utils';

export default sx((theme) => ({
  providers: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
  },

  providerBtn: {
    margin: '0 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    '&:first-of-type': {
      marginLeft: 0,
    },

    '&:last-of-type': {
      marginRight: 0,
    },
  },

  providerBtnIcon: {
    display: 'block',
    width: '22px !important',
    height: '22px !important',
    mr: '10px',
  },

  providerBtnlabel: {
    marginLeft: '10px',
  },

  phoneProviderForm: {
    position: 'relative',
    width: '100%',
  },

  phoneProvider: {
    position: 'relative',
    width: '100%',

    '& form': {
      display: 'block',
      width: '100%',
    },
  },

  formStep: {
    display: 'none',
  },

  visible: {
    display: 'flex',
    width: '100%',
  },

  phoneProviderInput: {
    marginTop: 0,
    flexGrow: 1,
  },

  phoneProviderbackBtn: {
    display: 'flex',
    justifyContent: 'center',
  },

  codeInput: {
    maxWidth: '56px',
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },

  errorWrap: {
    margin: '20px 0',
    color: theme?.palette?.error.main,
    textAlign: 'center',
  },

  nextBtn: {
    position: 'relative',
    margin: '0 0 0 20px',
    maxHeight: '56px',
  },
}));
