import React from 'react';
import { Box, Button, Avatar, Pagination, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridColumns, GridEventListener, GridSortModel } from '@mui/x-data-grid';

import { IModel } from '@api/models/admin/interface';
import { ITEMS_PER_PAGE } from 'src/constants/other';
import { admins as adminsApi } from 'src/store/api/admins';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { selectRole } from 'src/store/admin/selectors';
import { confirmation, setAlertMessage } from 'src/store/ux';
import { checkAdminPermissions, SCOPE } from 'src/components/common/RestrictedContent';
import sx from '../sx';

interface Props {
  onAccountEditClick: (accountData: IModel) => void;
}

type SortModel = { [K in keyof IModel]: 'asc' | 'desc' };

const List = (props: Props) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectRole);
  const theme = useTheme();
  const style = sx(theme);
  const [page, setPage] = React.useState(1);
  const [sortModel, setSortModel] = React.useState<SortModel | null>(null);
  const { data, isFetching } = adminsApi.endpoints.paginateAdmins.useQuery({
    sort: sortModel ? sortModel : undefined,
    limit: ITEMS_PER_PAGE,
    offset: (page - 1) * ITEMS_PER_PAGE,
  });

  const items = data?.success ? data?.data.items : [];
  const itemsTotal = data?.success ? data?.data.page.total : 0;
  const pagesTotal = Math.ceil(itemsTotal / ITEMS_PER_PAGE);

  const columns: GridColumns<IModel> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <Avatar alt={params.row.name} src={params.row.pictureUrl} sx={{ width: '24px', height: '24px' }} />
          <Typography variant="body2" fontWeight={900}>
            {params.row.name}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '15px' }}>
          <Button
            sx={style.actionBtn}
            onClick={async (e) => {
              dispatch(
                confirmation({
                  title: `Remove ${params.row.name} account?`,
                  confirmBtn: 'Remove',
                  callback: async (isConfirmed) => {
                    if (isConfirmed) {
                      const result = await dispatch(
                        adminsApi.endpoints.deleteAdmin.initiate(params.row._id.toString()),
                      );

                      if ('data' in result) {
                        if (result.data.success) {
                          dispatch(setAlertMessage({ severity: 'success', message: 'Account removed.' }));
                        } else {
                          dispatch(
                            setAlertMessage({ severity: 'error', message: result.data.error.message as string }),
                          );
                        }
                      } else {
                        dispatch(setAlertMessage({ severity: 'error', message: 'Something went wrong.' }));
                      }
                    }
                  },
                }),
              );
              e.stopPropagation();
            }}
          >
            Remove
          </Button>
        </Box>
      ),
    },
  ];

  if (!checkAdminPermissions(role, [SCOPE.USERS.DELETE])) {
    const actionsColumnIndex = columns.findIndex((col) => col.field === 'actions');
    if (actionsColumnIndex >= 0) columns.splice(actionsColumnIndex, 1);
  }

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const onSortModelChange = (model: GridSortModel) => {
    setPage(1);
    setSortModel(model[0] ? ({ [model[0].field]: model[0].sort } as SortModel) : null);
  };

  const onRowClick: GridEventListener<'rowClick'> = (params) => {
    props.onAccountEditClick(params.row as IModel);
  };

  return (
    <Box>
      <DataGrid
        sx={style.grid}
        autoHeight
        disableColumnMenu
        loading={isFetching || !data}
        onRowClick={onRowClick}
        getRowId={(row) => row._id.toString()}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        rows={items || []}
        columns={columns}
        isRowSelectable={() => true}
        isCellEditable={() => false}
        hideFooter
      />
      {pagesTotal > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination count={pagesTotal} onChange={onPageChange} />
        </Box>
      )}
    </Box>
  );
};

export default List;
