import React from 'react';
import { useField } from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { EditorState } from 'draft-js';
import { EditorProps } from 'react-draft-wysiwyg';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { useTheme } from '@mui/material/styles';

import { CAN_USE_DOM } from 'src/constants/other';
import sx from './sx';

let Editor: React.FC<EditorProps>;
let ReactDraftWysiwyg;
if (CAN_USE_DOM) {
  ReactDraftWysiwyg = require('react-draft-wysiwyg');
  Editor = ReactDraftWysiwyg.Editor; // eslint-disable-line
}

interface Props {
  name: string;
  label: string;
}

const RichTextField = (props: Props) => {
  const { name, label } = props;
  const [field, meta, helpers] = useField(name);
  const theme = useTheme();
  const style = sx(theme);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(convertFromHTML((field.value as string) || '')),
  );

  React.useEffect(() => {
    const html = convertToHTML(editorState.getCurrentContent());
    helpers.setValue(html);
  }, [editorState]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const onContainerClick = () => {
    const editor: any = document.querySelector('.public-DraftEditor-content');

    editor?.focus();
  };

  return (
    <Box sx={style.richField} onClick={onContainerClick}>
      <Typography className="label" sx={style.label}>
        {label}
      </Typography>
      <Editor
        toolbar={{
          options: ['inline', 'link', 'history'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic'],
          },
        }}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    </Box>
  );
};

export default RichTextField;
