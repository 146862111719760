import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import { FormikProvider, Form, useFormik } from 'formik';

import { IModel } from '@api/models/admin/interface';
import { ROLE } from '@api/models/admin/role';
import { Params, paramsSchema as updateParamsSchema } from '@api/routes/admins/update/params';
import { paramsSchema as createParamsSchema } from '@api/routes/admins/create/params';
import { TextField, Select, MediaField } from 'src/components/common/form';
import { LoadingButton } from 'src/components/common';
import { useModalContext } from 'src/hooks/modal';
import { useNextTick } from 'src/hooks/next-tick';
import { admins as adminsApi } from 'src/store/api/admins';
import { setAlertMessage } from 'src/store/ux';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { selectAdmin } from 'src/store/admin/selectors';
import sx from './sx';

type Props = {
  accountData: IModel | null;
};

type FormValues = Omit<Params, 'picture'> & { picture?: Blob | null; pictureUrl?: string };

const Details = (props: Props) => {
  const { accountData: adminData } = props;
  const formikContext = useFormik<FormValues>({
    enableReinitialize: true,
    validationSchema: adminData ? updateParamsSchema : createParamsSchema,
    initialValues: {
      email: adminData?.email ?? '',
      name: adminData?.name ?? '',
      phone: adminData?.phone ?? '',
      role: adminData?.role ?? ROLE.CONTENT_VIEWER,
      pictureUrl: adminData?.pictureUrl,
      picture: null,
    },
    onSubmit: async (values: FormValues) => {
      const result = !adminData
        ? await createAdmin({ params: values })
        : await updateAdmin({ id: adminData._id.toString(), params: values });

      formikContext.resetForm({ values });

      onNextTick(() => {
        if ('data' in result) {
          if (result.data.success) {
            const message = adminData ? 'Account updated successfully.' : 'Account created successfully.';
            dispatch(setAlertMessage({ severity: 'success', message }));
          } else {
            dispatch(setAlertMessage({ severity: 'error', message: result.data.error.message as string }));
          }
        } else {
          dispatch(setAlertMessage({ severity: 'error', message: 'Something went wrong.' }));
        }
        modalContext?.close();
      });
    },
  });
  const modalContext = useModalContext({
    blockingMessage: 'Unsaved data will be lost. Continue?',
    isBlocked: !formikContext.isSubmitting && formikContext?.dirty,
  });
  const admin = useAppSelector(selectAdmin);
  const theme = useTheme();
  const style = sx(theme);
  const dispatch = useAppDispatch();
  const onNextTick = useNextTick();
  const [createAdmin, createState] = adminsApi.endpoints.createAdmin.useMutation();
  const [updateAdmin, updateState] = adminsApi.endpoints.updateAdmin.useMutation();
  const tfSx: SxProps = { width: '100%', mt: 0 };

  const onCloseClick = () => {
    modalContext?.close();
  };

  return (
    <Box sx={style.container}>
      <FormikProvider value={formikContext}>
        <Form>
          <Typography textAlign="center" variant="h3" sx={{ mb: '40px' }}>
            Account Details
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <MediaField
              disabled={!!adminData && adminData._id.toString() !== admin._id}
              sx={{ borderRadius: '50%', mb: '30px', borderWidth: '5px' }}
              name="pictureUrl"
              value={formikContext.values.pictureUrl}
              onChange={(e) => formikContext.setFieldValue('picture', e.currentTarget.files[0])}
            />
            <TextField sx={tfSx} size="small" name="name" label="Name" placeholder={adminData?.name} />
            <TextField sx={tfSx} size="small" name="email" label="Email" placeholder={adminData?.email} />
            <TextField sx={tfSx} size="small" name="phone" label="Phone" placeholder={adminData?.phone} />
            <Select
              sx={{ width: '100%', mt: 0, '& .MuiFormControl-root': { width: '100%' } }}
              name="role"
              label="Role"
              size="small"
              options={Object.values(ROLE).map((role) => ({ label: role, value: role }))}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', mt: '40px' }}>
            <LoadingButton
              sx={{ minWidth: '120px' }}
              variant="contained"
              type="submit"
              label="Save"
              loading={createState.isLoading || updateState.isLoading}
              disabled={!formikContext.dirty}
            />

            <Button sx={{ width: '120px' }} onClick={onCloseClick}>
              Cancel
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default Details;
