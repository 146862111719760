import { sx } from 'src/utils';

export default sx((theme) => ({
  textField: {
    position: 'relative',
    margin: '20px 0',

    '& .MuiInputBase-root input': {
      borderRadius: 0,
    },

    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: `0 0 0 100px ${theme?.palette.background.alt} inset`,
    },
  },
  errorMessage: {
    display: 'block',
  },
  underline: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 0,
    height: '2px',
    backgroundColor: theme?.palette.grey[100],
    transition: 'all 0.2s ease-in-out',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
}));
