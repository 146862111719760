import React from 'react';

export type BlockingTexts = {
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
};

export type ModalContextType = {
  setBlockingTexts: (texts: BlockingTexts) => void;
  setIsBlocked: (value: boolean) => void;
  close: () => void;
};

export const ModalContext = React.createContext<ModalContextType>({
  setBlockingTexts: () => {
    // do nothing
  },
  setIsBlocked: () => {
    // do nothing
  },
  close: () => {
    // do nothing
  },
});
