import cookies from 'js-cookie';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { THEME } from 'src/constants/index';

export interface PreferencesState {
  theme: THEME;
}

const initialState: PreferencesState = {
  theme: (cookies.get('theme') as THEME) ?? THEME.DARK,
};

export const preferences = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<THEME>) => {
      state.theme = action.payload;
      cookies.set('theme', action.payload);
    },
  },
});

export const { setTheme } = preferences.actions;
export default preferences.reducer;
