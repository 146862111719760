import { API_HOST } from 'src/utils/api';
import {
  ROUTE as GET_META_ROUTE,
  Params as GetMetaParams,
  Result as GetMetaResult,
} from '@api/routes/data/meta/params';
import { ROUTE as UPLOAD_ROUTE, Params as UploadParams, Result as UploadResult } from '@api/routes/data/upload/params';

import { ApiResponse } from '@api/types';
import { renameFile } from 'src/utils/rename-file';
import api from '..';

export const data = api.injectEndpoints({
  endpoints: (builder) => ({
    getMeta: builder.query<ApiResponse<GetMetaResult>, GetMetaParams>({
      query: () => ({
        method: 'GET',
        url: GET_META_ROUTE,
      }),
      providesTags: () => [{ type: 'DATA', id: 'ALL' }],
    }),
    uploadData: builder.mutation<ApiResponse<UploadResult>, { params: UploadParams }>({
      queryFn: async ({ params }) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          const request = new XMLHttpRequest();

          if (params.data) formData.append('data', renameFile(params.data as File, 'data'));

          request.withCredentials = true;

          request.onload = () => {
            try {
              const response = JSON.parse(request.response);
              resolve({
                data: response as ApiResponse<UploadResult>,
              });
            } catch (e) {
              reject(e);
            }
          };
          request.open('POST', `${API_HOST}${UPLOAD_ROUTE}`);
          request.send(formData);
        });
      },
      invalidatesTags: () => [{ type: 'DATA', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
