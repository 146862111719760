import React from 'react';
import { Formik, Form } from 'formik';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier, ConfirmationResult } from 'firebase/auth';
import { Box, IconButton, InputAdornment, OutlinedInput, CircularProgress, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { phoneSignInParamsSchema, phoneCodeSignInParamsSchema } from '@api/routes/auth/admin/params';
import { setAuthProgressState } from 'src/store/admin';
import { TextField } from 'src/components/common/form';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { selectAuth } from 'src/store/admin/selectors';
import { signIn } from 'src/store/admin/actions/sign-in';

import sx from './sx';

interface Props {
  onBackClick: () => void;
}

const PhoneProvider = (props: Props) => {
  const [currentView, setCurrentView] = React.useState<'phone' | 'code'>('phone');
  const [isPhoneStageInProgress, setIsPhoneStageInProgress] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const recaptchaVerifier = React.useRef<RecaptchaVerifier>();
  const phoneInputRef = React.useRef<HTMLInputElement>(null);
  const confirmationResultRef = React.useRef<ConfirmationResult>();
  const codeInputs = React.useMemo(
    () => Array.from({ length: 6 }).map((_) => React.createRef<HTMLInputElement | null>()),
    [],
  );

  const auth = useAppSelector(selectAuth);
  const appDispatch = useAppDispatch();
  const theme = useTheme();
  const style = sx(theme);

  const onBackClick = () => {
    if (currentView === 'code') {
      setErrorMsg(null);
      setCurrentView('phone');
    } else {
      props.onBackClick();
    }
  };

  const onPhoneSubmitClick = async () => {
    if (recaptchaVerifier.current) {
      try {
        setIsPhoneStageInProgress(true);
        if (phoneInputRef.current?.value) {
          const auth = getAuth();
          const confirmationResult = await signInWithPhoneNumber(
            auth,
            phoneInputRef.current?.value,
            recaptchaVerifier.current,
          );
          confirmationResultRef.current = confirmationResult;

          setCurrentView('code');
          setIsPhoneStageInProgress(false);
        } else {
          throw new Error('No phone number provided');
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    } else {
      setIsPhoneStageInProgress(false);
      console.error('reCAPTCAH is undefined');
    }
  };

  const onCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = codeInputs.map((ref) => ref.current?.value).join('');

    if (code.length === codeInputs.length) {
      submitCode(code);
    } else {
      const currentInputIndex = codeInputs.findIndex((ref) => ref.current === e.currentTarget);
      const nextInput = codeInputs[currentInputIndex + 1];

      if (nextInput?.current) {
        nextInput.current.focus();
      }
    }
  };

  const submitCode = async (code: string) => {
    try {
      appDispatch(setAuthProgressState(true));
      const result = await confirmationResultRef.current?.confirm(code);

      if (result?.user?.phoneNumber) {
        const signInResult = await appDispatch(signIn({ provider: 'phone', phone: result.user.phoneNumber }));

        if (signInResult.meta.requestStatus === 'rejected') {
          console.log(signInResult);
          setErrorMsg(signInResult.payload as string);
          setAuthProgressState(false);
        }
      }
    } catch (e) {
      console.error((e as Error).message);
      setErrorMsg((e as Error).message);
      setAuthProgressState(false);
    }
  };

  React.useEffect(() => {
    const auth = getAuth();

    recaptchaVerifier.current = new RecaptchaVerifier(
      'submit-phone',
      {
        size: 'invisible',
        callback: () => console.log('reCAPTCHA solved'),
      },
      auth,
    );
  }, []);

  React.useEffect(() => {
    currentView === 'phone' ? phoneInputRef.current?.focus() : codeInputs[0].current?.focus();
  }, [currentView]);

  return (
    <Box sx={style.phoneProvider}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={currentView === 'phone' ? phoneSignInParamsSchema : phoneCodeSignInParamsSchema}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={async () => {
          // everything is already submited
        }}
      >
        <Form>
          <Box sx={{ ...style.formStep, ...(currentView === 'phone' && style.visible) }}>
            <TextField
              sx={style.phoneProviderInput}
              name="phone"
              label="Phone"
              autoComplete="phone"
              disabled={auth.isInProgress}
              inputRef={phoneInputRef}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    id="submit-phone"
                    onClick={onPhoneSubmitClick}
                    type="button"
                    disabled={isPhoneStageInProgress}
                  >
                    {isPhoneStageInProgress === false ? <SendIcon /> : <CircularProgress color="inherit" size={20} />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>

          <Box
            sx={{
              ...style.formStep,
              ...(currentView === 'code' && style.visible),
              justifyContent: 'center',
              mb: '20px',
              gap: '20px',
            }}
          >
            {codeInputs.map((ref, index) => (
              <OutlinedInput
                sx={style.codeInput}
                inputProps={{ maxLength: 1 }}
                inputRef={ref}
                key={index}
                onChange={onCodeInputChange}
                disabled={auth.isInProgress}
              />
            ))}
          </Box>
          {errorMsg && (
            <Box style={style.errorWrap}>
              <Typography>{errorMsg}</Typography>
            </Box>
          )}
          <Box sx={style.phoneProviderbackBtn}>
            <Button
              sx={{ minWidth: '140px', fontSize: '16px' }}
              size="small"
              variant="text"
              disabled={auth.isInProgress || isPhoneStageInProgress}
              onClick={onBackClick}
            >
              <KeyboardBackspaceIcon sx={{ mr: '10px' }} />
              <span>Back</span>
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

type FormValues = {
  provider: 'phone';
  phone: string;
  code: string;
};

const initialValues: FormValues = {
  provider: 'phone',
  phone: '',
  code: '',
};
/* <TextField sx={style.phoneProviderInput} name="code" label="" autoComplete="code" inputRef={codeInputRef} /> */
export default PhoneProvider;
