import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DarkModeOutlined from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlined from '@mui/icons-material/LightModeOutlined';

import { useAppDispatch } from 'src/hooks/store';
import { setTheme } from 'src/store/preferences';
import { THEME } from 'src/constants/index';
import sx from './sx';

const Content = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const styles = sx(theme);

  const onThemeToggleClick = () => {
    dispatch(setTheme(theme.palette.mode === 'dark' ? THEME.LIGHT : THEME.DARK));
  };

  React.useEffect(() => {
    const htmlElement = document.querySelector('html');

    htmlElement?.classList.remove('light', 'dark');
    htmlElement?.classList.add(theme.palette.mode);
  }, [theme.palette.mode]);

  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
      <IconButton sx={styles.themeToggleBtn} onClick={onThemeToggleClick}>
        {theme.palette.mode === 'dark' ? <DarkModeOutlined /> : <LightModeOutlined />}
      </IconButton>
      <Box sx={{ flexGrow: 1, p: `20px`, display: 'flex', flexDirection: 'column' }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Content;
