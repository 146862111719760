import { createSelector } from 'reselect';
import { RootState } from '..';

export const selectAdmin = (state: RootState) => {
  return state.admin;
};

export const selectRole = (state: RootState) => {
  return state.admin.role;
};

export const selectPictureUrl = (state: RootState) => {
  return state.admin.pictureUrl;
};

export const selectAuth = (state: RootState) => {
  return state.admin.auth;
};

export const selectName = createSelector(selectAdmin, (admin) => {
  if (admin.name) {
    return admin.name;
  }

  const nameByEmail = admin.email?.substring(0, admin.email.indexOf('@')).replaceAll('.', ' ');
  if (nameByEmail) {
    return nameByEmail;
  }

  return admin.phone;
});

export const selectShortName = createSelector(selectAdmin, (admin) => {
  if (admin.name) {
    const splittedName = admin.name.split(' ');

    return splittedName.length > 1
      ? `${splittedName[0].toUpperCase()}.${splittedName[1].toUpperCase()}`
      : admin.name[0].toUpperCase();
  } else if (admin.email) {
    const splittedName = admin.email.split('@')[0].split('.');

    return splittedName.length > 1
      ? `${splittedName[0].toUpperCase()}.${splittedName[1].toUpperCase()}`
      : admin.email[0].toUpperCase();
  } else if (admin.phone) {
    return admin.phone.substr(admin.phone.length - 3);
  }

  return null;
});
