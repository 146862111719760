import * as React from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { confirmation } from 'src/store/ux';
import { useAppDispatch } from './store';

export function useBlocker(blocker: (tx: any) => void, when = true) {
  const { navigator } = React.useContext(NavigationContext);

  React.useEffect(() => {
    if (!when) return;

    const unblock = (navigator as any).block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message: string, when = true) {
  const blocker = React.useCallback(
    (tx: any) => {
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );

  useBlocker(blocker, when);
}

export function useModalPrompt(message: string, when = true) {
  const dispatch = useAppDispatch();

  const blocker = React.useCallback(
    async (tx: any) => {
      dispatch(
        confirmation({
          title: message,
          callback: (result) => {
            if (result) tx.retry();
          },
        }),
      );
    },
    [message],
  );

  useBlocker(blocker, when);
}
