import React from 'react';
import { Navigate } from 'react-router-dom';

import { selectAuth } from 'src/store/admin/selectors';
import { routes } from 'src/constants';
import { useAppSelector } from 'src/hooks/store';

type Props = {
  redirectPath?: string;
  children: React.ReactNode;
};

const PrivateRoute = (props: Props) => {
  const { redirectPath = routes.LOGIN, children } = props;
  const auth = useAppSelector(selectAuth);

  if (!auth.isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
