import { sx } from 'src/utils';

export default sx((theme) => ({
  select: {
    position: 'relative',
    zIndex: 100000,
    margin: '20px 0',
  },
  errorMessage: {
    display: 'block',
  },
}));
