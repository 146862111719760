import React from 'react';

export const useNextTick = () => {
  const onUpdateCallback = React.useRef<(() => void) | null>(null);

  const onNextTick = React.useCallback((callback: () => void) => {
    onUpdateCallback.current = callback;
  }, []);

  React.useEffect(() => {
    if (onUpdateCallback.current) {
      requestAnimationFrame(onUpdateCallback.current);
      onUpdateCallback.current = null; // reset the callback after invoking it
    }
  });

  return onNextTick;
};
