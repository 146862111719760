import React from 'react';
import { useTheme } from '@mui/material';

const RabbiLogo = () => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 37.8" width="100%">
      <g fill={theme.palette.mode === 'dark' ? 'white' : 'black'}>
        <path d="M17.9,16.6c3.2-1.4,5.3-3.9,5.3-7.9V8.7c0-2.5-0.8-4.5-2.3-5.9C19.2,1,16,0,12.1, 0H0v25.4h15.4H24L17.9,16.6z M15.6,9.2c0,1.9-1.4,3-3.8,3H7.7V6.1h4.1C14.1,6.1,15.6,7.1,15.6,9.2L15.6,9.2z" />
      </g>
      <g id="diamond">
        <polygon
          points="7.7,37.8 15.3,25.4 7.7,13.1 7.7,13.1 7.7,13.1 7.7,13.1 7.7,13.1 7.7,13.1 7.7,13.1 7.7,13.1 0,25.4 7.7,37.8 7.7,37.8 7.7,37.8 7.7,37.8 7.7,37.8 7.7,37.8 7.7,37.8"
          style={{ fill: 'rgb(69, 198, 231)' }}
        />
        <polygon points="0,25.4 7.7,25.4 7.7,37.8" style={{ fill: 'rgb(1, 1, 1)' }} />
        <polygon points="15.3,25.4 7.7,37.8 7.7,25.4" style={{ fill: 'rgb(13, 192, 222)' }} />
        <polygon points="0,25.4 7.7,25.4 7.7,13.1" style={{ fill: 'rgb(3, 154, 206)' }} />
        <polygon points="15.3,25.4 7.7,13.1 7.7,25.4" style={{ fill: 'rgb(100, 197, 182)' }} />
        <polygon points="3.1,25.4 7.7,25.4 7.7,37.8" style={{ fill: 'rgb(3, 154, 206)' }} />
        <polygon points="12.2,25.4 7.7,37.8 7.7,25.4" style={{ fill: 'rgb(69, 198, 231)' }} />
        <polygon points="3.1,25.4 7.7,25.4 7.7,13.1" style={{ fill: 'rgb(69, 198, 231)' }} />
        <polygon points="12.2,25.4 7.7,13.1 7.7,25.4" style={{ fill: 'rgb(255, 255, 255)' }} />
      </g>
    </svg>
  );
};

export default RabbiLogo;
