import React from 'react';

import { ModalContext } from 'src/components/common/Modal';
import { useModalPrompt } from 'src/hooks/router';

type Params = {
  blockingMessage?: string;
  blockingConfirmLabel?: string;
  blockingCancelLabel?: string;
  isBlocked?: boolean;
};

export const useModalContext = (params?: Params) => {
  const modalContext = React.useContext(ModalContext);
  const texts = React.useMemo(
    () => ({
      message: params?.blockingMessage || '',
      confirmLabel: params?.blockingConfirmLabel || '',
      cancelLabel: params?.blockingCancelLabel || '',
    }),
    [params?.blockingMessage, params?.blockingConfirmLabel, params?.blockingCancelLabel],
  );

  // this will handle the case of URL change:
  useModalPrompt(params?.blockingMessage || '', params?.isBlocked);

  // this will handle the case of modal UI close/cancel:
  React.useEffect(() => {
    modalContext?.setBlockingTexts(texts);
    modalContext?.setIsBlocked(!!params?.isBlocked);
  }, [params?.blockingMessage, params?.isBlocked]);

  return modalContext;
};
