import React from 'react';
import { Helmet } from 'react-helmet-async';

import faviconUrl from 'src/assets/favicon/favicon.png';

interface Props {
  seo?: {
    title?: string;
    description?: string;
  };
  og?: {
    title?: string;
    description?: string;
    imageUrl?: string;
  };
}

const Head = (props: Props) => {
  const { seo, og } = props;
  const SEO = { title: 'Mako Iron Swords Admin', description: '', ...seo };
  const OG = { title: 'Mako Iron Swords Admin', description: '', ...og };

  return (
    <Helmet>
      <title>{SEO.title}</title>
      <meta name="description" content={SEO.description} />
      <meta property="og:title" content={OG.title} />
      <meta property="og:description" content={OG.description} />
      <link rel="icon" type="image/png" href={faviconUrl} />
    </Helmet>
  );
};

export default Head;
