import React from 'react';
import { Snackbar as MUISnackbar, Alert } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { selectUX } from 'src/store/ux/selectors';
import { setAlertMessage } from 'src/store/ux';

const Snackbar = () => {
  const dispatch = useAppDispatch();
  const ux = useAppSelector(selectUX);

  const closeAlert = React.useCallback(() => {
    dispatch(setAlertMessage(null));
  }, []);

  return (
    <MUISnackbar
      open={!!ux.alertMessage}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={closeAlert}
    >
      <Alert onClose={closeAlert} severity={ux.alertMessage?.severity} sx={{ width: '100%' }}>
        {ux.alertMessage?.message}
      </Alert>
    </MUISnackbar>
  );
};

export default Snackbar;
