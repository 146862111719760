import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import Box from '@mui/material/Box';
import { useTheme, alpha } from '@mui/material/styles';

import css from './Table.module.scss';

interface RowProps {
  index: number;
  selected?: boolean;
  isDragging?: boolean;
  handlerId?: Identifier | null;
  children: React.ReactNode;
  className?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

function Row(props: RowProps) {
  const { children, index, isDragging = false, className = '', onClick } = props;
  const theme = useTheme();

  let bgColor = alpha(theme.palette.mode === 'dark' ? '#fff' : '#000', 0.08);
  if (props.selected) {
    bgColor = alpha(
      theme.palette.mode === 'dark' ? theme.palette.primary[400]! : theme.palette.primary[400]!,
      0.2,
    ) satisfies string;
  }
  const opacity = isDragging ? 0.1 : 1;

  return (
    <Box
      className={className}
      data-index={index}
      data-handler-id={props.handlerId}
      style={{ opacity }}
      onClick={onClick}
      ref={props.containerRef}
    >
      <Box className={css.rowBg} sx={{ backgroundColor: bgColor }} />
      {children}
    </Box>
  );
}

interface SortableRowProps {
  id: string;
  index: number;
  selected?: boolean;
  children: React.ReactNode;
  className?: string;
  onDrag: (dragIndex: number, hoverIndex: number) => void;
  onDragStateChange?: (isDragging: boolean) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

function SortableRow<D>(props: SortableRowProps) {
  const { children, id, index, className = '', onDrag, onDragStateChange, onClick } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<D & { index: number }, void, { handlerId: Identifier | null }>({
    accept: 'row',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onDrag(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'row',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  React.useEffect(() => {
    if (onDragStateChange) {
      onDragStateChange(isDragging);
    }
  }, [isDragging]);

  drag(drop(ref));

  return (
    <Row
      className={className}
      index={index}
      handlerId={handlerId}
      isDragging={isDragging}
      onClick={onClick}
      containerRef={ref}
    >
      {children}
    </Row>
  );
}

export { Row, SortableRow };
