import { sx } from 'src/utils';

export default sx((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    minHeight: '100vh',
  },
  poster: {
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  sidebar: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    minWidth: '390px',
    maxWidth: '500px',
    marginLeft: 'auto',
    backgroundColor: theme?.palette.background.alt,
  },
  logoWrap: {
    width: '40px',
    mb: '20px',
  },
  footer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    textAlign: 'center',
    opacity: 0.35,
  },
}));
