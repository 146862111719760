import React from 'react';
import { Box, Button } from '@mui/material';

interface Props extends React.PropsWithChildren {
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: () => unknown;
  onCancel: () => unknown;
}

const Confirmation = (props: Props) => {
  const { children, confirmLabel = `להמשיך`, cancelLabel = `ביטול` } = props;

  return (
    <>
      <Box sx={{ textAlign: 'center', direction: 'rtl' }}>{children}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', mt: '40px' }}>
        <Button sx={{ width: '120px' }} onClick={props.onConfirm}>
          {confirmLabel}
        </Button>
        <Button sx={{ width: '120px' }} onClick={props.onCancel}>
          {cancelLabel}
        </Button>
      </Box>
    </>
  );
};

export default Confirmation;
