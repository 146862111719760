import React from 'react';
import { Box, useTheme, SxProps } from '@mui/material';

import sx from './sx';

type Props = {
  children?: React.ReactNode;
  sx?: SxProps;
};

const Header = (props: Props) => {
  const { children } = props;
  const theme = useTheme();
  const style = sx(theme);

  const mergedStyles = React.useMemo(() => {
    return {
      ...style.subHeader,
      ...(props.sx || {}),
    };
  }, [props.sx]);

  return <Box sx={mergedStyles}>{children}</Box>;
};

export default Header;
