import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ROLE } from '@api/models/admin/role';
import { IModel } from '@api/models/admin/interface';
import * as actions from './actions';

interface IAdmin extends Omit<IModel, '_id' | 'password'> {
  _id: string;
  auth: {
    isInProgress: boolean;
    isLoggedIn: boolean;
  };
}

const initialState: IAdmin = {
  _id: '',
  role: ROLE.CONTENT_VIEWER,
  auth: {
    isInProgress: false,
    isLoggedIn: false,
  },
};

export const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state._id = action.payload._id;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.phone = action.payload.phone;
      state.name = action.payload.name;
      state.pictureUrl = action.payload.pictureUrl;
      state.auth.isLoggedIn = true;
    },
    setAuthProgressState: (state, action: PayloadAction<boolean>) => {
      state.auth.isInProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.signIn.pending, (state) => {
      state.auth.isInProgress = true;
    });
    builder.addCase(actions.signIn.fulfilled, (state, action) => {
      state.auth.isInProgress = false;
      state.auth.isLoggedIn = true;

      if (action.payload.success) {
        state._id = action.payload.data._id.toString();
        state.email = action.payload.data.email;
        state.role = action.payload.data.role;
        state.phone = action.payload.data.phone;
        state.name = action.payload.data.name;
        state.pictureUrl = action.payload.data.pictureUrl;
      }
    });
    builder.addCase(actions.signIn.rejected, (state) => {
      state.auth.isInProgress = false;
    });

    builder.addCase(actions.signOut.pending, (state) => {
      state.auth.isInProgress = true;
    });
    builder.addCase(actions.signOut.fulfilled, (state) => {
      state.auth.isInProgress = false;
      state.auth.isLoggedIn = false;
    });
    builder.addCase(actions.signOut.rejected, (state) => {
      state.auth.isInProgress = false;
    });
  },
});

export const { init, setAuthProgressState } = admin.actions;
export default admin.reducer;
