import { SxProps } from '@mui/material';
import { ITheme } from '@mui/material/styles';

export const gridStyles = (theme?: ITheme, scrollable?: boolean): SxProps => ({
  border: 'none',
  '& .MuiDataGrid-main': {
    mb: '30px',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    boxSizing: 'content-box',
  },
  '& .MuiDataGrid-columnSeparator--sideRight': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    mr: '10px',
  },
  '& .MuiDataGrid-columnHeaders': {
    marginBottom: '0px',
    borderBottom: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-row': {
    cursor: 'pointer',
    borderRadius: '10px',
    ...(scrollable && { width: '100%' }),
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-footerContainer': {
    justifyContent: 'center',
    borderTop: 'none',
  },
  ...(scrollable && {
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      left: 0,
      right: '10px',
      width: 'calc(100% - 10px)',
    },
  }),
});
