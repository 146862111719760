import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import sx from './sx';

interface Props extends ButtonProps {
  loading: boolean;
  label: string;
}

const LoadingButton = (props: Props) => {
  const { loading, label } = props;
  const theme = useTheme();
  const style = sx(theme);

  return (
    <Button
      {...{ ...props, loading: undefined }}
      sx={{ ...(loading ? style.containerLoading : style.container), ...props.sx }}
    >
      <span>{label}</span>
      {loading && <CircularProgress color="inherit" size={props.size === 'small' ? 15 : 20} />}
    </Button>
  );
};

export default LoadingButton;
