import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useField } from 'formik';
import { Box, Typography, useTheme } from '@mui/material';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { mergeRefs } from 'src/utils';
import sx from './sx';

interface Props extends OutlinedInputProps {
  name: string;
  label: string;
  inputRef?: React.Ref<HTMLInputElement>;
  mui?: boolean;
}

const TextField = (props: Props) => {
  const { name, label, disabled, mui = true } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const hiddenInputRef = useRef<HTMLInputElement | null>(null);
  const [field, meta] = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [width, setWidth] = useState(0);
  const theme = useTheme();
  const style = sx(theme);
  const hasErrorToShow = !!(meta.touched && meta.error);

  const helperText = useMemo(() => {
    return Array.isArray(meta.error)
      ? (meta.error as string[]).map((error, index) => (
          <Typography sx={style.errorMessage} key={`${error}-${index}`}>
            {error}
          </Typography>
        ))
      : meta.error;
  }, [meta.error]);

  useEffect(() => {
    setWidth(hiddenInputRef.current?.offsetWidth || 0);
  }, [field.value]);

  return (
    <Box sx={{ ...style.textField, ...(disabled && style.disabled), ...props.sx }}>
      {mui ? (
        <FormControl variant="outlined" fullWidth error={hasErrorToShow} disabled={props.disabled}>
          <InputLabel size={props.size === 'small' ? 'small' : 'normal'} htmlFor={name}>
            {label}
          </InputLabel>
          <OutlinedInput id={name} {...field} {...props} inputRef={mergeRefs(inputRef, props.inputRef)} />
          {hasErrorToShow && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      ) : (
        <>
          <span style={{ position: 'absolute', visibility: 'hidden', whiteSpace: 'nowrap' }} ref={hiddenInputRef}>
            {field.value}
          </span>
          <input
            {...field}
            style={{ width: `${(hiddenInputRef.current?.offsetWidth || width) + 10}px` }}
            type="text"
            ref={mergeRefs(inputRef, props.inputRef)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <Box sx={{ ...style.underline, width: isFocused ? '100%' : '0' }} />
        </>
      )}
    </Box>
  );
};

export default TextField;
