import React from 'react';
import { useField } from 'formik';
import { Box, Stack, Typography, Switch, useTheme } from '@mui/material';

import sx from './sx';

interface Props {
  name: string;
  labelChecked?: string;
  labeleUnchecked?: string;
}

const SwitchField = (props: Props) => {
  const { name, labelChecked = '', labeleUnchecked = '' } = props;
  const [field, meta, helpers] = useField(name);
  const theme = useTheme();
  const style = sx(theme);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(e.target.checked);
  };

  return (
    <Box sx={{ ...style.switchField }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{labeleUnchecked}</Typography>
        <Switch onChange={handleChange} checked={field.value} inputProps={{ 'aria-label': 'ant design' }} />
        <Typography>{labelChecked}</Typography>
      </Stack>
    </Box>
  );
};

export default SwitchField;
